:root {
  --ion-color-primary: #cf0061;
  --ion-color-primary-rgb: 207, 0, 97;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #b60055;
  --ion-color-primary-tint: #d41a71;

  --ion-color-secondary: #77003d;
  --ion-color-secondary-rgb: 119, 0, 61;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #690036;
  --ion-color-secondary-tint: #851a50;

  --ion-color-tertiary: #ea82af;
  --ion-color-tertiary-rgb: 234, 130, 175;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #ce729a;
  --ion-color-tertiary-tint: #ec8fb7;

  --ion-color-success: #1c7d52;
  --ion-color-success-rgb: 28, 125, 82;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #196e48;
  --ion-color-success-tint: #338a63;

  --ion-color-warning: #feb420;
  --ion-color-warning-rgb: 254, 180, 32;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e09e1c;
  --ion-color-warning-tint: #febc36;

  --ion-color-danger: #df4848;
  --ion-color-danger-rgb: 223, 72, 72;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #c43f3f;
  --ion-color-danger-tint: #e25a5a;

  /** info **/
  --ion-color-info: #3880ff;
  --ion-color-info-rgb: 56, 128, 255;
  --ion-color-info-contrast: #ffffff;
  --ion-color-info-contrast-rgb: 255, 255, 255;
  --ion-color-info-shade: #3171e0;
  --ion-color-info-tint: #4c8dff;

  /** dark **/
  --ion-color-dark: #3d4149;
  --ion-color-dark-rgb: 61, 65, 73;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #363940;
  --ion-color-dark-tint: #50545b;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #dededf;
  --ion-color-light-tint: #f5f6f9;

  /** white **/
  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;

  /** Text **/
  --ion-text-color: #000000;
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

.ion-color-info {
  --ion-color-base: var(--ion-color-info);
  --ion-color-base-rgb: var(--ion-color-info-rgb);
  --ion-color-contrast: var(--ion-color-info-contrast);
  --ion-color-contrast-rgb: var(--ion-color-info-contrast-rgb);
  --ion-color-shade: var(--ion-color-info-shade);
  --ion-color-tint: var(--ion-color-info-tint);
}
