@import '@ionic/angular/css/core.css';

@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';

@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';

@import '@angular/material/theming';

@import 'https://fonts.googleapis.com/icon?family=Material+Icons';

@include mat-core();

$app-primary: mat-palette($mat-red, 700);
$app-accent: mat-palette($mat-red, 300);
$app-success: mat-palette($mat-green);
$app-warn: mat-palette($mat-red);
$app-theme: mat-light-theme(
  (
    color: (
      primary: $app-primary,
      accent: $app-accent,
      warn: $app-warn,
    ),
    typography: mat-typography-config(),
    density: 0,
  )
);
$app-theme-dark: mat-dark-theme(
  (
    color: (
      primary: $app-primary,
      accent: $app-accent,
      warn: $app-warn,
    ),
  )
);

@include angular-material-theme($app-theme);

// @media (prefers-color-scheme: dark) {
//   @include angular-material-color($app-theme-dark);
// }

.firebase-emulator-warning {
  display: none;
}

ion-select.arr-end::part(container) {
  width: 100%;
}

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}

.table-gen .mat-mdc-paginator-range-label,
.table-gen .mat-mdc-paginator-navigation-last {
  display: none;
}

.no-events {
  pointer-events: none;
}

.f-select {
  max-width: initial;
  width: 100%;
}

.flex-row,
.flex-radio {
  display: flex;
}

.max-width {
  width: 100%;
}

.max-height {
  height: 100%;
}

.vertical-spacing {
  margin: 1rem 0 1rem 0;
}

.clickable {
  cursor: pointer;
}

.sub-campaign-card {
  margin-left: 0;
  margin-right: 0;

  .mat-expansion-panel-body {
    padding: 0 16px 16px;
  }
}

.wide-modal {
  --max-height: 95%;
  --min-width: 400px;
  --height: 75%;
  --width: 70%;
}

.toast-style {
  text-align: center;
  font-size: 18px;
}

.ion-soft-margin-top {
  margin-top: 8px;
}

mat-table {
  width: 100%;
}

.mat-paginator-sticky {
  bottom: 0;
  position: sticky;
  z-index: 10;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--ion-color-dark);
}

ion-card {
  box-shadow:
    2px 4px 6px 0px #cbcbcb,
    -4px 0px 4px -5px #cbcbcb,
    0px -1px 4px 0px #f1f1f1;
  border-radius: 12px;
  padding: 6px;
}

.scroll-y {
  overflow-y: auto;
}

.middle-error-css {
  --width: 30% !important;
  --height: 35% !important;
  --min-width: 550px;
  --min-height: 400px;
  --max-height: 95%;
  --max-width: 95%;
  --border-radius: 12px;

  .alert-message {
    height: 100%;
  }
}

.alert-wrapper .alert-message {
  color: black !important;
  text-align: start !important;
}

.alert-wrapper .alert-title {
  text-align: center !important;
}

.alert-wrapper .alert-button {
  text-align: center !important;
  margin: 2px !important;
  width: 100%;
  border: 1px solid;
}

.alert-wrapper .alert-button .alert-button-inner {
  justify-content: center !important;
}

.delete-button {
  color: var(--ion-color-primary) !important;
}

.cancel-button {
  color: var(--ion-color-dark) !important;
}

.no-transform {
  text-transform: none;
}

.mat-menu {
  --mat-menu-container-shape: 8px !important;
  box-shadow:
    rgba(37, 30, 14, 0.12) 0px 2px 4px 0px,
    rgba(84, 61, 31, 0.32) 0px 2px 16px 0px !important;
}

.validation-button-spacing {
  margin-right: 12px !important;
}

ion-modal {
  &.mini-modal::part(content) {
    --height: 217px;
    --width: 500px;
    border-radius: 10px;
  }

  &.small-modal::part(content) {
    --height: 315px;
    --width: 500px;
    border-radius: 10px;
  }

  &.medium-modal::part(content) {
    --height: 590px;
    --width: 700px;
    border-radius: 10px;
  }

  &.wide-modal::part(content) {
    --width: 900px;
    border-radius: 10px;
  }
}

.wide-alert {
  .alert-wrapper {
    width: 400px;
  }
}

.status-container {
  --min-height: 16px;
}

.chip-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.preCodeSpanMargin {
  pre {
    margin: 10px -10px 10px 0px;
  }
}

.preCodeTitleMargin {
  pre {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.preCodeExplanation {
  pre {
    margin-top: 4px;
    margin-bottom: 4px;
    text-align: start !important;
  }
}

.fontSize30px {
  font-size: 30px !important;
}

.preCode {
  //span text font-size, 30px in teacher app
  //color: black;

  pre {
    border-radius: 12px;
    background: rgb(245, 245, 245);
    color: black;
    font-size: 0px;
    padding: 10px 7px;
    width: 100%;
    white-space: break-spaces;
  }

  code {
    line-height: 1.5;
    font-size: 22px; //only the program code font-size
  }
}

.confirm-alert-dialog {
  .alert-wrapper {
    .alert-title {
      text-align: left !important;
    }
    .alert-message {
      text-align: left !important;
    }
    .alert-button {
      margin-right: 15px !important;
      width: 80px !important;
      border: 1px solid !important;
      padding: 5px !important;
    }
  }
}

expand-item.topic ion-item {
  margin-left: 40px !important;
}

[id^="knowledge-elements"] .selected-item {
  // must be in the global.scss because the use of innerHTML
  color: var(--ion-color-primary) !important;
  font-weight: 500 !important;
}

[id^="knowledge-elements"] .hidden-item {
  // must be in the global.scss because the use of innerHTML
  display: none;
}

.import-generation-selector-alert {
  .alert-message {
    color: red !important;
    white-space: normal !important;
    word-wrap: break-word !important;
    overflow-wrap: break-word !important;
    max-width: 100% !important;
  }
}

.no-generations-available {
  .alert-radio-icon {
    display: none !important;
  }
  .alert-radio-label {
    padding: 13px 26px 13px 26px !important;
    text-align: center;
  }
}
